<template>
  <div class="mt-3">
    <v-card color="transparent" elevation="0">
      <v-card-title>
        <v-btn
          :to="{ name: 'configs.insurers_portal' }"
          icon
          exact
          class="mr-2"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        {{ insurer.name }}
      </v-card-title>
    </v-card>
    <v-card height="calc(100vh - 200px)">
      <v-navigation-drawer absolute permanent left>
        <v-list nav>
          <v-list-item
            v-for="link in links"
            :key="link.name"
            :to="link.to"
            color="primary"
          >
            <v-list-item-title>
              <v-icon left>{{ link.icon }}</v-icon>
              {{ link.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-card-text style="padding-left: 270px">
        <router-view v-if="insurer" :insurer="insurer"></router-view>
      </v-card-text>
    </v-card>

    <!-- <ConfigPage>
      <ConfigItem title="0" v-if="insurer">
        <template v-slot:title>
          <div>
            <v-btn :to="{ name: 'configs.insurers_portal' }" icon exact>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            {{ insurer.name }}
          </div>
        </template>

      </ConfigItem>
    </ConfigPage> -->
  </div>
</template>

<script>
import { show } from "@/services/configs/insurers_portal";
import ToastsMixin from "@/mixins/ToastsMixin";

export default {
  mixins: [ToastsMixin],

  components: {},

  props: {
    id: String,
  },

  data() {
    return {
      insurer: null,
      loading: false,
      links: [
        {
          icon: "mdi-shape-outline",
          name: "Tema",
          to: {
            name: "configs.insurers_portal.theme",
          },
        },
        {
          icon: "mdi-account-multiple-outline",
          name: "Utilizadores",
          to: {
            name: "configs.insurers_portal.users",
          },
        },
      ],
    };
  },

  methods: {
    async loadInsurer() {
      this.insurer = await show(this.id);
    },
  },

  computed: {},

  created() {
    this.loadInsurer();
  },
};
</script>

<style lang="scss" style>
.color-rectangle {
  width: 70px;
  height: 30px;
  border: 1px solid #ccc;
  margin-right: auto;
}
</style>
