import Vue from "vue";

export async function index() {
  const response = await Vue.prototype.$http.get(`/configs/insurers`);

  return response.data.data;
}

export async function show(id) {
  const response = await Vue.prototype.$http.get(`/configs/insurers/${id}`);

  return response.data.data;
}

export async function updateLogo(id, logo) {
  const formData = new FormData();
  formData.append("logo", logo);

  const response = await Vue.prototype.$http.post(
    `/configs/insurers/${id}/update_main_logo`,
    formData
  );

  return response.data.data;
}

export async function updateTheme(id, payload) {
  const response = await Vue.prototype.$http.post(
    `/configs/insurers/${id}/update_theme`,
    payload
  );

  return response.data.data;
}

export async function updateHeaderLogo(id, logo) {
  const formData = new FormData();
  formData.append("logo", logo);

  const response = await Vue.prototype.$http.post(
    `/configs/insurers/${id}/update_header_logo`,
    formData
  );

  return response.data.data;
}

export async function updateHeader(id, payload) {
  const response = await Vue.prototype.$http.post(
    `/configs/insurers/${id}/update_header`,
    payload
  );

  return response.data.data;
}
